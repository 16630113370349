import { Box, Flex } from '@chakra-ui/react';
import Banners from '@ui/components/Banners';
import { Layout } from '@ui/components/Layout';
import {
  RenderInDesktop,
  RenderInMobile,
} from '@ui/components/MobileOrDesktop';
import Sidebar from '@ui/components/Sidebar';
import { SEOTemplate } from '@ui/SEO';
import type { NextPage } from 'next';

import { AmplyToken } from './AmplyToken';
import { Audits } from './Audits';
import { FAQSection } from './FAQSection';
import { Introduction } from './Introduction';
import { Offer } from './Offer';
import StyledBackground from './StyledBackground';

export const Landing: NextPage = () => {
  return (
    <>
      <SEOTemplate
        title={
          'Amply Finance | Peer-to-Pool Lending Protocol on Cronos zkEVM Chain'
        }
        description={
          "Amply Finance is a peer-to-pool borrow and lending protocol on the Cronos zkEVM Chain. Access instant liquidity, earn interest on your assets, and participate in the protocol's growth with $AMPLY token."
        }
      />
      <RenderInDesktop>
        <Layout isFullWidth>
          <Flex gap={25}>
            <Sidebar />
            <Box maxW={'800px'}>
              <StyledBackground />
              <Banners />
              <Introduction />
              <Offer />
              <AmplyToken />
              <Audits />
              <FAQSection />
            </Box>
          </Flex>
        </Layout>
      </RenderInDesktop>
      <RenderInMobile>
        <Layout>
          <Box maxW={'800px'}>
            <StyledBackground />
            <Banners />
            <Introduction />
            <Offer />
            <AmplyToken />
            <Audits />
            <FAQSection />
          </Box>
        </Layout>
      </RenderInMobile>
    </>
  );
};
